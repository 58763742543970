import "./ArrayExtensions";
import { ArrayPredicate } from "./ArrayExtensions";
import { MaybeReadonlyArray } from "./Types";

export type ItemIdentifier<T, TThis> = ArrayPredicate<T, TThis> | T | number;

export function insert<T, TThis = void>(array: readonly T[], items: MaybeReadonlyArray<T>, itemToInsertBefore: ItemIdentifier<T, TThis>, thisArg?: TThis) {
    return moveBefore(array, items, itemToInsertBefore, thisArg);
}

export function matchOrder<T, Key>(source: readonly T[], order: readonly Key[], selectKey: (item: T) => Key) {
    if (order.length != source.length)
        throw new Error("Ordered keys array length doesn't match source array length");
    return order.map(key => source.findOrThrow(item => selectKey(item) == key));
}

export function move<T, TThis = void>(array: readonly T[], itemsToMove: ItemIdentifier<T, TThis>, delta: number, thisArg?: TThis) {
    return moveTo(array, itemsToMove, itemsToMove, delta, thisArg);
}

export function moveBefore<T, TThis = void>(
    array: readonly T[],
    itemsToMove: ItemIdentifier<T, TThis> | readonly T[],
    itemToMoveNextTo: ItemIdentifier<T, TThis>,
    thisArg?: TThis
) {
    return moveTo(array, itemsToMove, itemToMoveNextTo, 0, thisArg);
}

export function moveAfter<T, TThis = void>(
    array: readonly T[],
    itemsToMove: ItemIdentifier<T, TThis> | T[],
    itemToMoveNextTo: ItemIdentifier<T, TThis>,
    thisArg?: TThis
) {
    return moveTo(array, itemsToMove, itemToMoveNextTo, 1, thisArg);
}

export function moveTo<T, TThis = void>(
    array: readonly T[],
    itemsToMove: ItemIdentifier<T, TThis> | readonly T[],
    itemToMoveNextTo: ItemIdentifier<T, TThis>,
    deltaFromTarget: number,
    thisArg?: TThis
) {
    let movingItems = Array.isArray(itemsToMove) ? itemsToMove
        : typeof itemsToMove == 'function' ? array.filter(itemsToMove as ArrayPredicate<T, TThis>, thisArg)
            : typeof itemsToMove == 'number' ? [array[itemsToMove]]
                : [itemsToMove];
    let targetItem = typeof itemToMoveNextTo == 'function' ? array.findOrThrow(itemToMoveNextTo as ArrayPredicate<T, TThis>, thisArg)
        : typeof itemToMoveNextTo == 'number' ? array[itemToMoveNextTo]
            : itemToMoveNextTo;

    if (movingItems.includes(targetItem) && deltaFromTarget > 0)
        deltaFromTarget++;

    let targetItemIndex = array.indexOf(targetItem);
    let targetIndex = targetItemIndex >= 0 ? targetItemIndex + deltaFromTarget : array.length;
    let itemAtTargetIndex = array[targetIndex];

    return itemAtTargetIndex
        ? array.before(itemAtTargetIndex).except(movingItems)
            .concat(movingItems)
            .concat([itemAtTargetIndex].except(movingItems))
            .concat(array.after(itemAtTargetIndex).except(movingItems))
        : array.except(movingItems)
            .concat(movingItems);
}

export function range(start: number, count?: number): number[] {
    if (count == null) {
        count = start;
        start = 0;
    }

    let array = [];
    for (let i = 0; i < count; i++)
        array[i] = i + start;
    return array;
}

export function repeat<T>(item: T, count: number): T[] {
    let array = [];
    for (let i = 0; i < count; i++)
        array.push(item);
    return array;
}

export function iterate<T>(firstItem: T, getNextItem: (item: T) => T | null | undefined): T[] {
    let array = [] as T[];
    let item = firstItem as T | null | undefined;
    do {
        array.push(item!);
        item = getNextItem(item!);
    } while (item != null);

    return array;
}

export function treePreOrder<T>(nodes: readonly T[], getChildren: (node: T) => (readonly T[] | undefined)): T[] {
    return nodes.flatMap(n => [n, ...treePreOrder(getChildren(n) || [], getChildren)]);
}

export function arraysEqual(arrays: readonly (readonly any[])[]) {
    return arrays.length
        ? zip(arrays).every(items => items.every(i => i == items[0]))
        : true;
}

export function zip<T1>(arrays: [readonly T1[]]): [T1][];
export function zip<T1, T2>(arrays: [readonly T1[], readonly T2[]]): [T1 | undefined, T2 | undefined][];
export function zip<T1, T2, T3>(arrays: [readonly T1[], readonly T2[], readonly T3[]]): [T1 | undefined, T2 | undefined, T3 | undefined][];
export function zip<T1, T2, T3, T4>(arrays: [readonly T1[], readonly T2[], readonly T3[], readonly T4[]]): [T1 | undefined, T2 | undefined, T3 | undefined, T4 | undefined][];
export function zip<T1, T2, T3, T4, T5>(arrays: [readonly T1[], readonly T2[], readonly T3[], readonly T4[], readonly T5[]]): [T1 | undefined, T2 | undefined, T3 | undefined, T4 | undefined, T5 | undefined][];
export function zip(arrays: readonly (readonly any[])[]): (any | undefined)[][];
export function zip<TResult, T1, TThis = void>(arrays: [readonly T1[]], selector: (this: TThis, item1: T1) => TResult, thisArg?: TThis): TResult[];
export function zip<TResult, T1, T2, TThis = void>(arrays: [readonly T1[], readonly T2[]], selector: (this: TThis, item1: T1, item2: T2) => TResult, thisArg?: TThis): TResult[];
export function zip<TResult, T1, T2, T3, TThis = void>(arrays: [readonly T1[], readonly T2[], readonly T3[]], selector: (this: TThis, item1: T1, item2: T2, item3: T3) => TResult, thisArg?: TThis): TResult[];
export function zip<TResult, T1, T2, T3, T4, TThis = void>(arrays: [readonly T1[], readonly T2[], readonly T3[], readonly T4[]], selector: (this: TThis, item1: T1, item2: T2, item3: T3, item4: T4) => TResult, thisArg?: TThis): TResult[];
export function zip<TResult, T1, T2, T3, T4, T5, TThis = void>(arrays: [readonly T1[], readonly T2[], readonly T3[], readonly T4[], readonly T5[]], selector: (this: TThis, item1: T1, item2: T2, item3: T3, item4: T4, item5: T5) => TResult, thisArg?: TThis): TResult[];
export function zip<TResult, TThis = void>(arrays: readonly (readonly any[])[], selector: (this: TThis, ...items: any[]) => TResult, thisArg?: TThis): TResult[];
export function zip<TThis = void>(arrays: readonly (readonly any[])[], selector?: (this: TThis, ...items: any[]) => any, thisArg?: TThis) {
    selector = selector || ((...items) => items);
    let maxLength = arrays.map(a => a.length).max();

    return range(maxLength)
        .map(i => selector!.apply(thisArg as TThis, arrays.map(array => array[i])));
}