import { cloneReactElement, combineRefs, forwardRef } from '@zap/utils/lib/ReactHelpers';
import * as React from 'react';
import { useEffect } from "react";
import { DomRegistrar, IDomRegistrar, useDomScope } from "./DomScope";

export interface IClickOutsideProps {
    onClick(event: MouseEvent): void;
}

export const ClickOutside = forwardRef(function ClickOutside(props: IClickOutsideProps & { children: React.ReactElement }, ref: React.Ref<any>) {
    let domRegistrar = useClickOutside(props.onClick);
    let childRef = domRegistrar.useChildRef();
    return <DomRegistrar.Provider value={domRegistrar}>
        {cloneReactElement(props.children, { ref: combineRefs(ref, childRef) })}
    </DomRegistrar.Provider>;
});

/**
 * Given the following scenario:
 * 
 * <document>
 *      <ExplorerButton />
 *      <ExplorerPopup />
 *      <DialogBackground>
 *          <Dialog>
 *              <ComboBox>
 *              </ComboBox>
 *          </Dialog>
 *      </DialogBackground>
 * </document>
 * 
 * useClickOutside should work like this:
 * 
 * |                  |                            Clicked outside                            |
 * | Clicked on       | ExplorerButton | ExplorerPopup | DialogBackground | Dialog | ComboBox |
 * |------------------------------------------------------------------------------------------|
 * | ExplorerButton   |        x       |       x       |         o        |   o    |     o    |
 * | ExplorerPopup    |        x       |       x       |         o        |   o    |     o    |
 * | DialogBackground |        x       |       x       |         x        |   o    |     o    |
 * | Dialog           |        x       |       x       |         x        |   x    |     o    |
 * | ComboBox         |        x       |       x       |         x        |   x    |     x    |
 * 
 * Two main tricky issues:
 * 
 * 1) Clicking on ExplorerButton or ExplorerPopup should not count as clicking outside the other.
 *    The solution is to use a DomScope, and only trigger when clicking outside _all_ the elements in scope.
 * 
 * 2) Clicking on DialogBackground should not trigger the callback for ExplorerButton and ExplorerPopup.
 *    To this end, DialogBackground stops mousedown propagation. The problem is that to detect clicks,
 *    we need to hook up to mousedown on document, but mousedowns on DialogBackground never reach the
 *    document, and nothing gets fired. The solution is to use a capturing handler and handle clicks in
 *    the scope's branch immediately, but handle clicks in other branches only if they reach the document.
 */
export function useClickOutside(onClickOutside: (e: MouseEvent) => void): IDomRegistrar {
    let domScope = useDomScope();

    useEffect(() => {
        document.addEventListener('mousedown', onMouseDown, true);
        return () => {
            document.removeEventListener('mousedown', onMouseDown, true);
            removeDocumentMouseDownHandler();
        }
    });

    return domScope.registrar;

    function onMouseDown(e: MouseEvent) {
        if (clickedInScopeBranch(e))
            checkIfClickedOutside(e);
        else
            waitUntilEventReachesDocumentBeforeChecking();
    }

    function clickedInScopeBranch(e: MouseEvent) {
        return domScope.elements.some(el => (e.target as HTMLElement).contains(el));
    }

    function waitUntilEventReachesDocumentBeforeChecking() {
        document.addEventListener('mousedown', checkIfClickedOutside, { once: true });
        setTimeout(removeDocumentMouseDownHandler); // In case the event's propagation is stopped
    }

    function checkIfClickedOutside(e: MouseEvent) {
        if (clickedOutsideOfEverythingInScope(e) && !clickedOnScrollbar(e))
            onClickOutside(e);
    }

    function removeDocumentMouseDownHandler() {
        return document.removeEventListener('mousedown', checkIfClickedOutside);
    }

    function clickedOutsideOfEverythingInScope(e: MouseEvent) {
        return domScope.elements.none(el => el.contains(e.target as HTMLElement));
    }

    function clickedOnScrollbar(event: MouseEvent) {
        let el = event.target as HTMLElement;
        let hasScrollBars = el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight;

        // client height/width do not include scroll bars, so click event with offsets outside this must be inside scroll bar
        return hasScrollBars && (event.offsetY > el.clientHeight || event.offsetX > el.clientWidth);
    }
}