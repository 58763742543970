import { cloneReactElement, combineRefs, forwardRef, useEventListener } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Column, Row } from "./Box";
import { ClickOutside } from "./ClickOutside";
import { blackColor, defaultFont, defaultFontColor, fadeInAnimation, roundedBorders, standardBorder, zIndexes } from "./CommonStyles";
import { bottomDivider } from "./Divider";
import { SmallHeading } from "./Headings/Headings";
import { isHoverSupported, useHover } from "./Hover";
import { Icon } from "./Icons/Icon";
import { Alignment, getAnchorContext as createAnchor, IPopupAnchorContext, Popup, useMouseAnchor } from "./Popup";
import { Elevation } from "./shadow";
import { Side } from "./Side";
import { doubleColumn, halfSpacing } from "./Sizes";
import { style, Styled } from "./styling";
import { useTimeout } from "./Timeout";

export const defaults = {
    helpText: "Help"
};

export interface ITooltipContentProps {
    title?: React.ReactNode;
    content?: React.ReactNode;
    helpText?: string;
    helpUrl?: string;
    minWidth?: number;
}

export interface ITooltipCommonProps extends ITooltipContentProps {
    position?: Side | 'pointer';
    align?: Alignment;
    anchor?: IPopupAnchorContext;
    quick?: boolean;
    onShow?(): void;
}

export interface ITooltipForExternalElementProps extends ITooltipCommonProps {
    for: React.RefObject<HTMLElement>;
    children?: undefined;
}

export interface ITooltipForChildProps extends ITooltipCommonProps {
    children: React.ReactElement;
}

export const Tooltip = forwardRef(function Tooltip(props: ITooltipForExternalElementProps | ITooltipForChildProps, outerRef: React.Ref<any>) {
    let childRef = useRef<HTMLElement>(null);
    let popupRef = useRef<HTMLDivElement>(null);

    let forRef = 'for' in props ? props.for : childRef;

    let mouseAnchor = useMouseAnchor(true);

    let alignAnchor = props.position == 'pointer' ? mouseAnchor : createAnchor(forRef);
    let positionAnchor = props.anchor ?? alignAnchor;

    let child = props.children
        ? cloneReactElement(props.children, { ref: combineRefs(outerRef, childRef), [dataTooltip]: props.content })
        : null;

    let [isHovering, noAnimation, hide] = useHoverScrubbing([forRef, popupRef], props.quick ? 200 : 900, 50);

    useMemo(() => {
        if (isHovering && props.onShow)
            props.onShow();
    }, [isHovering]);

    useEventListener('mousedown', onSourceMousedown, false, forRef);

    let side = props.position == 'pointer' ? 'bottom' : props.position;
    let align = props.align ?? (props.position == 'pointer' ? 'start' : 'center');

    return <>
        {child}
        <Popup elevation={Elevation.None} zIndex={zIndexes.tooltip} anchorAlign={alignAnchor} anchorPosition={positionAnchor} anchorOffset={halfSpacing} show={isHovering && !!props.content} side={side} align={align} minWidth={props.minWidth ?? 50} noAnimation={noAnimation} fixed>
            <ClickOutside onClick={hide} ref={popupRef}>
                <Column spacing="half" styles={[tooltip, !noAnimation && fadeInAnimation]}>
                    {props.title && <SmallHeading text={props.title} />}
                    <Styled.div styles={typeof props.content == 'string' && tooltipText}>{props.content}</Styled.div>
                    {props.helpUrl &&
                        <>
                            <Row styles={bottomDivider} />
                            <Row>
                                <a href={props.helpUrl} target="_blank">
                                    <Row spacing="half">
                                        <Icon name="helpOutline" size="tiny" />
                                        <span>{props.helpText ?? defaults.helpText}</span>
                                    </Row>
                                </a>
                            </Row>
                        </>
                    }
                </Column>
            </ClickOutside>
        </Popup>
    </>;

    function onSourceMousedown(event: MouseEvent) {
        if (event.button == 0)
            hide();
    }
});

function useHoverScrubbing(elementRefs: React.RefObject<HTMLElement>[], showDelayMs: number, hideDelayMs: number) {
    if (!isHoverSupported)
        showDelayMs = hideDelayMs = 0;

    let [setTimeout, clearTimeout] = useTimeout();

    let [isHovering, setIsHovering] = useState(false);
    let [immediate, setImmediate] = useState(false);

    useEffect(() =>
        () => {
            if (stopHoveringImmediately === stopHovering)
                stopHoveringImmediately = null; // Prevent state updates if this component is unmounted 
        }
        , []);

    useHover(elementRefs,
        () => {
            if (isHovering)
                return clearTimeout(); // Mouse out, then back in before timeout

            if (stopHoveringImmediately) { // Started hovering again before previous tooltip was hidden
                setTimeout(() => {
                    if (stopHoveringImmediately)
                        stopHoveringImmediately();
                    startHovering(true);
                }, hideDelayMs);
            } else {
                setTimeout(() => startHovering(false), showDelayMs); // First hover
            }
        },
        () => {
            if (isHovering)
                setTimeout(stopHovering, hideDelayMs); // Moused out after tooltip shown
            else
                clearTimeout(); // Moused out before tooltip had a chance to be shown
        });

    return [isHovering, immediate, stopHovering] as const;

    function startHovering(immediate: boolean) {
        setIsHovering(true);
        setImmediate(immediate);
        stopHoveringImmediately = stopHovering;
    }

    function stopHovering() {
        setIsHovering(false);
        stopHoveringImmediately = null;
        clearTimeout();
    }
}

let stopHoveringImmediately = null as (() => void) | null;

let tooltip = style('tooltip', {
    ...defaultFont,
    ...defaultFontColor,
    ...roundedBorders,
    border: standardBorder(blackColor),
    padding: halfSpacing,
    background: 'white',
    maxWidth: doubleColumn
});

export let tooltipText = style('tooltip-text', {
    whiteSpace: 'pre-line'
});

export const dataTooltip = 'data-tooltip';
