import { useEffect, useRef, useState } from "react";

export function useMousePosition(): MouseCoords {
    let coords = useRef({ x: 0, y: 0 });
    useWindowMouseMove(onMouseMove);
    return coords;

    function onMouseMove(event: MouseEvent) {
        coords.current = { x: event.pageX, y: event.pageY };
    }
}

export interface MouseCoords {
    current: {
        x: number,
        y: number
    }
}

export function useWindowMouseMove(onMouseMove: (e: MouseEvent) => void) {
    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove);
        return () => window.removeEventListener('mousemove', onMouseMove);
    });
}

export function useIsMouseDownOnElement<T extends HTMLElement>() {
    let ref = useRef<T>(null);
    let [isMouseDown, setIsMouseDown] = useState(false);

    useEffect(() => {
        ref.current?.addEventListener('mousedown', onMouseDown);
        window.addEventListener('mouseup', onMouseUp);

        return () => {
            ref.current?.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('mouseup', onMouseUp);
        }
    }, []);

    return [ref, isMouseDown] as const;

    function onMouseDown(e: MouseEvent) {
        setIsMouseDown(isMousePressed(e));
    }

    function onMouseUp() {
        setIsMouseDown(false);
    }
}

export function isMousePressed(e: MouseEvent) {
    return e.buttons === 1;
}
