import { useEffect, useRef } from "react";

export const defaultThrottleMs = 500;

export function useThrottle<T extends (...args: any) => void>(fn: T, timeoutMs: number = defaultThrottleMs) {
    let [setTimeout, clearTimeout] = useTimeout();

    return (...args: Parameters<T>) => {
        clearTimeout();
        setTimeout(() => fn(...args), timeoutMs);
    }
}

export function useThrottledValue<T>(fn: (...args: any) => Promise<T>, timeoutMs: number = defaultThrottleMs) {
    let [setTimeout, clearTimeout] = useTimeout();

    return (...args: Parameters<(...args: any) => Promise<T>>) => {
        clearTimeout();
        return new Promise<T>(resolve => setTimeout(() => resolve(fn(...args)), timeoutMs));
    }
}

export function useTimeout() {
    let timeout = useRef(null as any);

    useEffect(() => () => clearTimeout(timeout.current), []);

    return [
        (fn: () => void, delayMs: number) => {
            clearTimeout(timeout.current);
            if (delayMs)
                timeout.current = setTimeout(fn, delayMs);
            else
                fn();
        },
        () => clearTimeout(timeout.current)
    ] as const;
}