import { dataProps } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { Center, Column } from "./Box";
import { greyColor, labelFont } from "./CommonStyles";
import { animation, style, Styled } from "./styling";
import { IconSizes, IconSize } from "./Icons/Icon";
import { useEffect, useState } from "react";
import { useTimeout } from "./Timeout";

export interface ILoadingProps {
    message?: string;
    size?: IconSize
}

export function Loading({ message, size = 'large', ...props }: ILoadingProps) {
    let sizeStyles = { large, small, tiny }[size];
    return <Column role="progressbar" alignItems="center" {...dataProps(props)}>
        <Center spacing="none" styles={sizeStyles}>
            <Styled.div styles={[spinner]}>
                <Styled.div styles={layer}>
                    <Styled.div styles={[circleClipper, left]}>
                        <Styled.div styles={circle}></Styled.div>
                    </Styled.div>
                    <Styled.div styles={gapPatch}>
                        <Styled.div styles={circle}></Styled.div>
                    </Styled.div>
                    <Styled.div styles={[circleClipper, right]}>
                        <Styled.div styles={circle}></Styled.div>
                    </Styled.div>
                </Styled.div>
            </Styled.div>
        </Center>
        {message && <Center styles={loadingText}>{message}</Center>}
    </Column>;
}

export const defaultLoadingDelay = 500;

export function useLoadingDelay(isLoading: boolean | undefined, delayMs = defaultLoadingDelay) {
    let [show, setShow] = useState(false);
    let [setTimeout, clearTimeout] = useTimeout();

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => setShow(true), delayMs);
        } else {
            setShow(false);
            clearTimeout();
        }
    }, [isLoading, delayMs]);

    return show;
}

let arcSize = 270; // Amount of circle the arc takes up.
let arcTime = 1333; // Time it takes to expand and contract arc.
let arcStartRot = 216; // How much the start location of the arc should rotate each time.
let duration = 360 * arcTime / (arcStartRot + (360 - arcSize));
let timing = 'cubic-bezier(0.4, 0, 0.2, 1)';

let spinner = style('spinner', {
    display: 'inline-block',
    verticalAlign: 'middle',
    fontSize: 0,
    lineHeight: 0,
    color: greyColor,
    position: 'relative',
    width: '100%',
    height: '100%',
    animation: animation({ to: { transform: { rotate: 360 } } }, duration, 'linear', 0, 'infinite'),
});

let circle = style('spinner-circle', {
    position: 'absolute',
    boxSizing: 'border-box',
    height: '100%',
    borderStyle: 'solid',
    borderColor: 'inherit',
    borderBottomColor: 'transparent',
    borderRadius: '50%'
});

let left = style('spinner-left', {
    $: {
        [`.${circle}`]: {
            borderRightColor: 'transparent',
            transform: { rotate: 129 },
            animation: animation({
                from: { transform: { rotate: 130 } },
                '50%': { transform: { rotate: -5 } },
                to: { transform: { rotate: 130 } }
            }, arcTime, timing, 0, 'infinite', 'normal', 'both')
        }
    }
});

let right = style('spinner-right', {
    $: {
        [`.${circle}`]: {
            left: '-100%',
            borderLeftColor: 'transparent',
            transform: { rotate: -129 },
            animation: animation(
                {
                    from: { transform: { rotate: -130 } },
                    '50%': { transform: { rotate: 5 } },
                    to: { transform: { rotate: -130 } }
                }, arcTime, timing, 0, 'infinite', 'normal', 'both')
        }
    }
});

let large = style('spinner-large', {
    width: IconSizes.large,
    height: IconSizes.large,
    $: {
        [`.${circle}`]: {
            borderWidth: 3
        }
    }
});

let small = style('spinner-small', {
    width: IconSizes.small,
    height: IconSizes.small,
    padding: 3,
    $: {
        [`.${circle}`]: {
            borderWidth: 2,
        }
    }
});

let tiny = style('spinner-tiny', {
    width: IconSizes.tiny,
    height: IconSizes.tiny,
    padding: 1,
    $: {
        [`.${circle}`]: {
            borderWidth: 2,
        }
    }
});

let layer = style('spinner-layer', {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderColor: 'currentColor',
    /**
    * HACK: Even though the intention is to have the current .spinner-layer-N
    * at `opacity: 1`, we set it to `opacity: 0.99` instead since this forces Chrome
    * to do proper subpixel rendering for the elements being animated. This is
    * especially visible in Chrome 39 on Ubuntu 14.04. See:
    *
    * - https://github.com/Polymer/paper-spinner/issues/9
    * - https://code.google.com/p/chromium/issues/detail?id=436255
    */
    opacity: 0.99,

    animation: animation({
        '12.5%': { transform: { rotate: 0.5 * arcSize } },
        '25%': { transform: { rotate: arcSize } },
        '37.5%': { transform: { rotate: 1.5 * arcSize } },
        '50%': { transform: { rotate: 2 * arcSize } },
        '62.5%': { transform: { rotate: 2.5 * arcSize } },
        '75%': { transform: { rotate: 3 * arcSize } },
        '87.5%': { transform: { rotate: 3.5 * arcSize } },
        to: { transform: { rotate: 4 * arcSize } }
    }, (4 * arcTime), timing, 0, 'infinite', 'normal', 'both')
});


/**
* Patch the gap that appear between the two adjacent
* div.spinner-circleClipper while the spinner is rotating
* (appears on Chrome 38, Safari 7.1, and IE 11).
*
* Update: the gap no longer appears on Chrome when .spinner-layer's
* opacity is 0.99, but still does on Safari and IE.
*/
let gapPatch = style('spinner-gapPatch', {
    position: 'absolute',
    boxSizing: 'border-box',
    top: 0,
    left: '45%',
    width: '10%',
    height: '100%',
    overflow: 'hidden',
    borderColor: 'inherit',
    $: {
        [`.${circle}`]: {
            width: '1000%',
            left: '-450%',
        }
    }
});

let circleClipper = style('spinner-circleClipper', {
    display: 'inline-block',
    position: 'relative',
    width: '50%',
    height: '100%',
    overflow: 'hidden',
    borderColor: 'inherit',
    $: {
        [`.${circle}`]: {
            width: '200%'
        }
    }
});

let loadingText = style('loading-text', {
    ...labelFont,
    color: greyColor,
    marginTop: 4
});
