export type Comparator<T> = (a: T, b: T) => number;

export function ascending<T>(): Comparator<T>;
export function ascending<T, U>(selector: (item: T) => U): Comparator<T>;
export function ascending<T, U>(selector: (item: T) => U = (i => i as any)) {
    return (a: T, b: T) => {
        let aVal = selector(a);
        let bVal = selector(b);
        if (typeof aVal == 'string' && typeof bVal == 'string')
            return aVal.localeCompare(bVal);
        return aVal > bVal ? 1 : aVal < bVal ? -1 : 0;
    }
}

export function descending<T>(): Comparator<T>;
export function descending<T, U>(selector: (item: T) => U): Comparator<T>;
export function descending<T, U>(selector: (item: T) => U = (i => i as any)) {
    let asc = ascending(selector);
    return (a: T, b: T) => -asc(a, b);
}

export function compareMultiple<T>(comparators: Comparator<T>[]) {
    return (a: T, b: T) => {
        for (let comparator of comparators) {
            let result = comparator(a, b);
            if (result)
                return result;
        }
        return 0;
    }
}