import { animation, border, style } from "./styling";

export const highlightColor = '#00a996';
export const darkHighlightColor = '#007968';
export const lightHighlightColor = '#a8f0e5';
export const hoverHighlightColor = '#ebfffc';
export const selectedHighlightColor = lightHighlightColor;
export const errorColor = '#e45847';
export const successColor = '#55ab55';
export const warningColor = '#ffa500';
export const blackColor = '#0c0c0c';
export const greyColor = '#5c5c5c';
export const whiteColor = '#fcfcfc';
export const darkGreyColor = '#333333';
export const lightGreyColor = '#e6e6e6';
export const lightGreyBorderColor = '#d9d9d9';
export const lightGreyFocusColor = '#d4d4d4';
export const lightGreyFontColor = '#999999'
export const blueColor = '#35bbe8';
export const linkColor = '#39d3b7';

export const interactiveForegroundColor = '#757575';
export const interactiveForegroundHoverColor = '#212121';
export const disabledForeground = '#b8b8b8';
export const disabledBackground = '#ebebe4';

export const pageBackgroundColor = 'hsl(0, 0%, 94%)';
export const toolWindowBackgroundColor = '#ffffff';
export const toolWindowSectionHeaderColor = lightGreyColor;
export const toolWindowSectionHeaderBorderColor = lightGreyBorderColor;

export const formControlBackground = '#f5f5f5';
export const formControlHover = '#ececec';
export const formControlFocus = '#ececec';

export const semiBoldFontWeight = 600;

export const duration = {
    /** Small movements, like a switch */
    small: 100,
    /** Things appearing out of nowhere, like a menu */
    appear: 120,
    /** Larger movements */
    medium: 250,
    /** Sliding in from the edge */
    slideIn: 250,
    /** Sliding out to the edge */
    slideOut: 200,
};

export const standardBorderColor = lightGreyColor;
export const standardBorderWidth = 1;
export const standardBorderStyle = 'solid';
export const standardBorder = (color = standardBorderColor) => border(standardBorderWidth, standardBorderStyle, color);
export const roundedBorderRadius = 2;
export const roundedBorders = style('roundedBorders', { borderRadius: roundedBorderRadius });


export const uninteractiveOpacity = 0.8;
export const interactiveOpacity = 0.56;
export const hoverOpacity = 0.7;
export const disabledOpacity = 0.26;
export const hoverBackgroundOpacity = 0.06;
export const focusBackgroundOpacity = 0.12;
export const activeBackgroundOpacity = 0.2;
export const focusRingOpacity = 0.4;

export const fontSizes = {
    small: 10,
    label: 12,
    default: 14,
    headingSmall: 14,
    headingLarge: 18
}

export const defaultFontColor = style('defaultFontColor', { color: blackColor });
export const labelFontColor = style('labelFontColor', { color: greyColor });
export const lightFontColor = style('lightFontColor', { color: lightGreyFontColor });

export const labelFontSize = style('labelFontSize', { fontSize: fontSizes.label });
export const defaultFontSize = style('defaultFontSize', { fontSize: fontSizes.default });
export const smallHeadingFontSize = style('smallHeadingFontSize', { fontSize: fontSizes.headingSmall });
export const largeHeadingFontSize = style('largeHeadingFontSize', { fontSize: fontSizes.headingLarge });

export const lineHeight = 1.2;

export const defaultFontFamily = style('defaultFontFamily', { fontFamily: '"Open Sans", "Segoe UI", Helvetica, Tahoma, sans-serif' });

export const defaultFont = style('defaultFont', { ...defaultFontFamily, ...defaultFontSize });
export const labelFont = style('labelFont', { ...defaultFontFamily, ...labelFontSize });

export const inlineBlock = style('inlineBlock', { display: 'inline-block' });

export const pageContentWidth = '1200px';

export const truncateText = style('truncateText', {
    flexShrink: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    $: {
        'body &': { // Just to override BI's .reactComponent * { flex-shrink: 0; }
            flexShrink: 1,
        }
    }
});

export const hideIfEmpty = style('hideIfEmpty', {
    ':empty': { display: 'none' }
});

export const zIndexes = {
    belowPage: -1,
    page: 0,
    abovePage: 1,
    popup: 400,
    tooltip: 700,
    loadingOverlay: 800,
    notification: 900
}

export const fadeInAnimation = style('fadeIn', {
    animation: {
        keyframes: {
            from: { opacity: 0 },
            to: { opacity: 1 }
        },
        animationDuration: duration.small
    }
});

export const fadeOutAnimation = style('fadeOut', {
    animation: animation({
        from: { opacity: 1 },
        to: { opacity: 0 }
    }, duration.small, 'ease')
});

export const flexGrow = style('flexGrow', {
    flexGrow: 1
});

export const flexShrink = style('flexShrink', {
    flexShrink: 1
});
