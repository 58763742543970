import { functor } from "@zap/utils/lib/Function";
import { valueOf } from "@zap/utils/lib/Object";
import { dataProps, nonDataProps, useMaybeControlledState, useRefCombiner, useSemiControlledState } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { Ref, useRef, useState } from "react";
import { Key } from "ts-key-enum";
import { halfBleed, IBoxProps, Row } from "./Box";
import { defaultFontFamily, defaultFontSize, disabledBackground, disabledForeground, flexGrow, flexShrink, formControlBackground, formControlFocus, formControlHover, roundedBorders } from "./CommonStyles";
import { DisabledContext, useDisabled } from "./Disabling";
import { fieldAsRef, IFormFieldProps, useFormContext, useFormLabelSpacing, useFormOnKeyDown } from "./Form";
import { FormControl } from "./FormControl";
import { IFieldHelperTextProps } from "./HelperText";
import { standardFormControlHeight } from "./Sizes";
import { defaultPx, style, StyleCollection, Styled } from "./styling";
import { IFieldUnderlineProps } from "./Underline";

export interface IInputProps<TValue, TField = HTMLInputElement>
    extends
    Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'value' | 'defaultValue' | 'onFocus' | 'onBlur' | 'ref'>,
    IFieldUnderlineProps<TValue>,
    IFieldHelperTextProps<TValue>,
    IFormFieldProps<TField> {

    value?: TValue;
    defaultValue?: TValue;
    /** Typed, only called on enter and blur */
    onValueChange?(value: TValue): void;
    onFocus?: React.FocusEventHandler<HTMLElement>;
    onBlur?: React.FocusEventHandler<HTMLElement>;
    label?: string;
    forceShowValidation?: boolean;
    styles?: StyleCollection;
    grow?: boolean;
    shrink?: boolean;
    flex?: string;
    templateText?: string;
    transparent?: boolean;
    hideUnderline?: boolean;
}

export interface IInputConfig<TValue, TField> {
    type?: string;
    role?: string;
    getValue?(input: HTMLInputElement): TValue | undefined;
    getField?(input: HTMLInputElement): TField;
    /** Allows the input to be left with an invalid value when it loses focus */
    allowInvalidValue?: boolean;
    inputRowProps?: IBoxProps;
    inputProps: IInputProps<TValue, TField>;
    getText?(value: TValue): string;
    tag?: 'input' | 'textarea';
    styles?: StyleCollection;
}

interface IInputRenderProps {
    before?: React.ReactNode;
    after?: React.ReactNode;
    outerRef?: Ref<HTMLDivElement>;
    inputRef?: Ref<HTMLInputElement>;
}

export function useInput<TValue, TField = HTMLInputElement>(inputConfig: IInputConfig<TValue, TField>) {
    let {
        type = 'text',
        role = 'textbox',
        getValue = (input: HTMLInputElement) => input.value as any as TValue,
        getField = (input: HTMLInputElement) => input as any as TField,
        inputProps: props,
        getText = String,
        tag = 'input' as const,
        allowInvalidValue,
        styles: outerStyles,
        inputRowProps: { styles: inputRowStyles, ...inputRowOverrides } = {},
    } = inputConfig;

    let inputRef = useRef<HTMLInputElement>(null);

    let fieldRef = (input: HTMLInputElement | null) => input && fieldAsRef(props.field)(getField(input));
    let formKeyDown = useFormOnKeyDown();
    let form = useFormContext();

    let { label, value, defaultValue, width, grow, shrink, flex, className, forceShowValidation, onFocus, onBlur, onChange, onValueChange, templateText, placeholder, isFocused, showEmptyHelper, disabled,
        transparent, hideUnderline,
        isValid,
        isLoading = false,
        styles: inputStyles,
        helperText,
        warningText,
        errorText,
        ...inputProps } = props;
    inputProps = nonDataProps(inputProps);
    disabled = useDisabled(disabled);

    let [isDirty, setDirty] = useState(false);
    let [currentValue, setCurrentValue] = useMaybeControlledState(defaultValue, value);
    let [currentText, setCurrentText] = useSemiControlledState(undefined, getText(currentValue));

    let showValidation = forceShowValidation || isDirty || form.submitted;
    let isInputValid = showValidation && !disabled
        ? functor(isValid)(currentValue)
        : undefined;

    let hasEmptyValue = currentValue === undefined
        || typeof currentValue == 'string' && !currentValue;

    let isEmpty = hasEmptyValue
        && !props.defaultValue
        && !typeHasDefaultPlaceholder();

    let formSpacing = useFormLabelSpacing(label, true);

    let outerRefs = useRefCombiner<HTMLDivElement>();
    let inputRefs = useRefCombiner<HTMLInputElement>();

    return {
        disabled,
        render(extraProps: IInputRenderProps) {
            return <DisabledContext disabled={disabled}>
                <Styled.div
                    styles={[textbox, halfBleed, grow && flexGrow, shrink && flexShrink, formSpacing, outerStyles]}
                    className={className}
                    inline={{ width, flex }}
                    role={role}
                    onClick={() => inputRef.current?.focus()}
                    {...dataProps(props)}>
                    <FormControl
                        label={label}
                        isValid={isInputValid}
                        isFocused={isFocused}
                        isLoading={functor(isLoading)(currentValue)}
                        disabled={disabled}
                        hideUnderline={hideUnderline && !isLoading}
                        showEmptyHelper={showEmptyHelper}
                        helperText={functor(helperText)(currentValue)}
                        warningText={showValidation ? functor(warningText)(currentValue) : undefined}
                        errorText={showValidation ? functor(errorText)(currentValue) : undefined}
                        onFocus={onFocus}
                        onBlur={blur}>
                        {({ getFocusProps, isFocused, inputRef: formControlInputRef, focusRef }) =>
                            <Row positioned spacing="none" sidePadding
                                minHeight={standardFormControlHeight}
                                styles={[transparent ? transparentStyle : lightStyle, isFocused && focusedStyle, disabled && disabledStyle, inputRowStyles]}
                                tabIndex={disabled ? undefined : -1}
                                ref={outerRefs(extraProps.outerRef, focusRef)}
                                {...getFocusProps({ onFocus: onInputRowFocus })}
                                {...inputRowOverrides}>
                                {extraProps.before}
                                <Styled
                                    tag={tag}
                                    type={type}
                                    ref={inputRefs(inputRef, formControlInputRef, extraProps.inputRef, fieldRef)}
                                    value={currentText}
                                    styles={[input, halfBleed, inputStyles]}
                                    placeholder={placeholder}
                                    disabled={disabled}
                                    {...inputProps}
                                    onChange={changed}
                                    onKeyDown={keydown}
                                    data-validity={isInputValid ?? true}
                                    role="" />{/* prevent implicit role on the input, there's an explicit role on the label */}
                                {!!templateText && (isFocused || !isEmpty || !label)
                                    && <Styled.span styles={[templateTextStyle, halfBleed]}>{templateText}</Styled.span>}
                                {extraProps.after}
                            </Row>}
                    </FormControl>
                </Styled.div>
            </DisabledContext>
        }
    };

    function onInputRowFocus(event: React.FocusEvent<HTMLDivElement>) {
        if (event.target == event.currentTarget) // Only care when the row itself is getting focus
            inputRef.current?.focus();
    }

    function changed(event: React.ChangeEvent<HTMLInputElement>) {
        setCurrentText(event.target.value);
        dirtyEventOverride(event, onChange);
    }

    function keydown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key == Key.Enter)
            updateValue(event.currentTarget);
        inputProps.onKeyDown?.(event);
        dirtyEventOverride(event, formKeyDown);
    }

    function blur(event: React.FocusEvent<HTMLInputElement>) {
        updateValue(inputRef.current!);
        dirtyEventOverride(event, onBlur);
    }

    function updateValue(input: HTMLInputElement) {
        fieldRef(input);

        let value = getValue(input);
        if (value === undefined) {
            if (!allowInvalidValue)
                setCurrentText(getText(currentValue));
        } else {
            setCurrentValue(value);
            if (valueOf(value) !== valueOf(currentValue))
                onValueChange?.(value);
            setCurrentText(getText(value));
        }
    }

    function dirtyEventOverride<T extends React.SyntheticEvent<HTMLInputElement>>(event: T, originalHandler?: (event: T) => void) {
        setDirty(true);
        originalHandler?.(event);
    }

    function typeHasDefaultPlaceholder() {
        switch (props.type) {
            case 'date':
            case 'datetime-local':
            case 'month':
            case 'time':
            case 'week':
                return true;
            default:
                return false;
        }
    }
}

let textbox = style('textbox', {
    display: 'inline-block',
    position: 'relative'
});

let focusedStyle = style('is-textbox-focused', {
    outline: 'none'
});

let lightStyle = style('textbox-light', {
    ...roundedBorders,
    background: formControlBackground,
    ':hover': {
        background: formControlHover,
    },
    $: {
        [`&.${focusedStyle}`]: {
            background: formControlFocus
        }
    }
});

let transparentStyle = style('textbox-transparent', {
    background: 'transparent'
});

let disabledStyle = style('is-textbox-disabled', {
    color: disabledForeground,
    background: disabledBackground,
    ':hover': { background: disabledBackground }
});

export const textBoxLineHeight = 20;
export const textBoxVerticalPadding = (standardFormControlHeight - textBoxLineHeight) / 2;

let input = style('textbox-input', {
    ...defaultFontSize,
    ...defaultFontFamily,
    border: 'none',
    outline: 'none',
    flex: 1,
    width: 0,
    minHeight: standardFormControlHeight,
    padding: [textBoxVerticalPadding, 0],
    lineHeight: defaultPx(textBoxLineHeight),
    overflow: 'hidden',
    background: 'transparent',
    $: {
        '::-webkit-search-cancel-button': {
            display: 'none'
        }
    }
});

let templateTextStyle = style('textbox-template', {
    color: disabledForeground,
    height: standardFormControlHeight,
    lineHeight: `${standardFormControlHeight}px`
});
