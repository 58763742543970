﻿import { applyCommonGlobalStyles } from "@zap/ui/lib/GlobalStyles";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { updateStylesheet } from "stylemap";
import global from "../../../../SharedSource/Web.Core/Client/global";
import { EmbeddedLoginForm } from "./EmbeddedLoginForm";
import { LoginForm } from "./LoginForm";
import { LoginPageModel } from "./LoginPageModel";
import { enableDevTools } from "event-reduce";

let model = new LoginPageModel();
enableDevTools(model);

applyCommonGlobalStyles();
updateStylesheet();

(global as any).boot = (rootElement: HTMLElement, props: ILoginPageProps) => {
    if (isInIFrame())
        ReactDOM.render(<EmbeddedLoginForm model={model.embedded} {...props} />, rootElement);
    else if (props.authSupportsForm)
        ReactDOM.render(<LoginForm model={model.login} {...props} />, rootElement);
    else
        location.href = props.challengeUrl;
}

function isInIFrame() {
    try {
        return window.location.href != window.parent.location.href;
    } catch {
        return true; // Blocked from accessing a cross-origin parent, so must be in an iframe
    }
}

export interface ILoginPageProps {
    authUrl: string;
    challengeUrl: string;
    returnUrl: string;
    headerBackgroundColor: string;
    headerImage: string;
    isPopup: boolean;
    authSupportsForm: boolean;
}
