import { zip as zipStatic } from "./Array";
import "./MapExtensions";
import "./FunctionExtensions";
import { ascending, Comparator } from "./Sorting";
import { extendPrototype } from "./ExtensionHelpers";

let readonlyArrayExtensions = {
    after,
    before,
    cast,
    clone,
    count,
    distinct,
    distinctRight,
    elementsAt,
    except,
    filterOut,
    findLast,
    findLastIndex,
    findOrThrow,
    flat,
    flatMap,
    group,
    intersection,
    isSubsetOf,
    last,
    lastOrNull,
    max,
    maxBy,
    min,
    none,
    notFalsy,
    notUndefined,
    notNullOrUndefined,
    reduceMap,
    singleOrNull,
    skipAfter,
    skipWhile,
    sortBy,
    sortStable,
    sum,
    symmetricDifference,
    toLookup,
    toMap,
    toObject,
    toSet,
    window,
    zip,
};
let mutatingArrayExtensions = {
    insert,
    remove,
    removeAll,
};
extendPrototype(Array, readonlyArrayExtensions as Partial<Array<any>>);
extendPrototype(Array, mutatingArrayExtensions as Partial<Array<any>>);

type ReadOnlyArrayExtensions = typeof readonlyArrayExtensions;
type MutableArrayExtensions = ReadOnlyArrayExtensions & typeof mutatingArrayExtensions;
declare global {
    interface ReadonlyArray<T> extends ReadOnlyArrayExtensions {
        cast<U extends T>(): readonly U[];
    }

    interface Array<T> extends MutableArrayExtensions {
        cast<U extends T>(): U[];
    }
}

export type ArrayCallback<TIn = any, TOut = any, TThis = void> = (this: TThis, item: TIn, index: number, array: readonly TIn[]) => TOut;
export type ArrayPredicate<T = any, TThis = void> = ArrayCallback<T, boolean, TThis>;

// Implementations below:

export function after<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis>, thisArg?: TThis): T[];
export function after<T, TThis = void>(this: readonly T[], item: T | undefined, thisArg?: TThis): T[];
export function after<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis> | T, thisArg?: TThis) {
    if (typeof predicate != 'function') {
        let afterItem = predicate;
        predicate = (item) => item === afterItem;
    }

    let firstMatching = this.findIndex(ignoreUndefined(predicate), thisArg);
    return firstMatching == -1 ? [] : this.slice(firstMatching + 1);
}

export function before<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis>, thisArg?: TThis): T[];
export function before<T, TThis = void>(this: readonly T[], item: T | undefined, thisArg?: TThis): T[];
export function before<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis> | T, thisArg?: TThis) {
    if (typeof predicate != 'function') {
        let beforeItem = predicate;
        predicate = (item) => item === beforeItem;
    }

    let firstMatching = this.findIndex(ignoreUndefined(predicate), thisArg);
    return firstMatching == -1 ? this : this.slice(0, firstMatching);
}

/** Just for TS typing */
export function cast<T, U extends T>(this: readonly T[]): readonly U[] {
    return this as U[];
}

export function clone<T>(this: readonly T[]) {
    return this.slice(0);
}

export function count<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis>, thisArg?: TThis): number {
    return this.filter(predicate, thisArg).length;
}

export function distinct<T, TValue = T, TThis = void>(this: readonly T[], keySelector?: ArrayCallback<T, TValue, TThis>, thisArg?: TThis): T[] {
    keySelector = keySelector || ((i: T) => i as any as TValue);
    return Array.from(this.reduceMap(keySelector, first => first, undefined!, thisArg).values());
}

export function distinctRight<T, TValue, TThis = void>(this: readonly T[], keySelector?: ArrayCallback<T, TValue, TThis>, thisArg?: TThis): T[] {
    return this.clone().reverse()
        .distinct((item, i) => keySelector
            ? keySelector.call(thisArg as TThis, item, this.length - i - 1, this)
            : item)
        .reverse();
}

export function elementsAt<T>(this: readonly T[], ...indices: number[]): T[] {
    return indices.map(i => this[i]);
}

export function except<T>(this: readonly T[], items: readonly T[]): T[];
export function except<T, U>(this: readonly T[], items: readonly U[], areEqual: (a: T, b: U) => boolean): T[];
export function except<T, U>(this: readonly T[], items: readonly U[], areEqual?: (a: T, b: U) => boolean): T[] {
    if (areEqual)
        return this.filter(item => items.none(otherItem => areEqual(item, otherItem)));
    let otherSet = items.toSet();
    return this.filter(i => !otherSet.has(i as any));
}

export function filterOut<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis>, thisArg?: TThis): T[] {
    return this.filter(predicate.negate(), thisArg);
}

export function findLast<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis>, thisArg?: TThis): T | undefined {
    return this[this.findLastIndex(predicate, thisArg)];
}

export function findLastIndex<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis>, thisArg?: TThis): number {
    predicate = predicate || function () { return true; };
    for (let i = this.length - 1; i >= 0; i--) {
        if (i in this && predicate.call(thisArg as TThis, this[i], i, this))
            return i;
    }
    return -1;
}

export function findOrThrow<T, S extends T, TThis = void>(this: readonly T[], predicate: (this: TThis, item: T, index: number, obj: readonly T[]) => item is S, thisArg?: TThis): S;
export function findOrThrow<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis>, thisArg?: TThis): T;
export function findOrThrow<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis>, thisArg?: TThis): T {
    let firstIndex = this.findIndex.call(this, ignoreUndefined(predicate), thisArg);
    if (firstIndex != -1)
        return this[firstIndex];
    throw new Error('Predicate does not match any items');
}

export function flatMap<T, U, TThis = void>(this: readonly T[], select: ArrayCallback<T, U, TThis>, thisArg?: TThis) {
    return this.map(select, thisArg).flat();
}

export function flat<T, U>(this: U[][][][][][][][], depth: 7): U[];
export function flat<T, U>(this: U[][][][][][][], depth: 6): U[];
export function flat<T, U>(this: U[][][][][][], depth: 5): U[];
export function flat<T, U>(this: U[][][][][], depth: 4): U[];
export function flat<T, U>(this: U[][][][], depth: 3): U[];
export function flat<T, U>(this: U[][][], depth: 2): U[];
export function flat<T, U>(this: U[][], depth?: 1): U[];
export function flat<T, U>(this: U[], depth: 0): U[];
export function flat<T, U>(depth?: number): any[];
export function flat(this: any[], depth: number = 1) {
    return depth > 0
        ? this.reduce((a, b) => a.concat(b), []).flat(depth - 1)
        : this;
}

export interface IGrouping<TKey, T> extends Array<T> {
    key: TKey;
}

export function group<T, TKey, TThis = void>(this: readonly T[], keySelector: ArrayCallback<T, TKey, TThis>, thisArg?: TThis): IGrouping<TKey, T>[] {
    return Array.from(this.reduceMap(keySelector, (a, b) => a.concat([b]), [] as any[], thisArg))
        .map(([key, group]) => Object.assign(group, { key }));
}

export function insert<T>(this: T[], index: number, item: T): void {
    this.splice(index, 0, item);
}

export function intersection<T>(this: readonly T[], other: T[]): T[] {
    let otherSet = other.toSet();
    return this.filter(i => otherSet.has(i));
}

export function isSubsetOf<T>(this: readonly T[], other: T[]): boolean;
export function isSubsetOf<T, U>(this: readonly T[], other: U[], areEqual: (a: T, b: U) => boolean): boolean;
export function isSubsetOf<T, U>(this: readonly T[], other: U[], areEqual?: (a: T, b: U) => boolean): boolean {
    return !this.except(other, areEqual!).length;
}

export function last<T>(this: readonly T[]): T {
    if (this.length)
        return this[this.length - 1];
    throw new Error("Sequence is empty");
}

export function lastOrNull<T>(this: readonly T[]): T | null {
    return this.length
        ? this[this.length - 1]
        : null;
}

export function max(this: number[]): number {
    return Math.max.apply(Math, this);
}

export function maxBy<T>(this: readonly T[], selector: (item: T) => number): T {
    return this.reduce(function (max, current) {
        return selector(current) > selector(max)
            ? current
            : max;
    });
}

export function min(this: number[]): number {
    return Math.min.apply(Math, this);
}

export function none<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis>, thisArg?: TThis): boolean {
    return !this.some.apply(this, arguments as any);
}

export function notFalsy<T>(this: readonly T[]): Exclude<T, undefined | null | false | 0 | ''>[] {
    return this.filter(Boolean) as Exclude<T, undefined | null | false | 0 | ''>[];
}

export function notUndefined<T>(this: readonly T[]): Exclude<T, undefined>[] {
    return this.filter(v => typeof v != 'undefined') as Exclude<T, undefined>[];
}

export function notNullOrUndefined<T>(this: readonly T[]): Exclude<T, null | undefined>[] {
    return this.filter(v => v != null) as Exclude<T, null | undefined>[];
}

export function reduceMap<T, TKey, TValue = T, TThis = void>(this: readonly T[], keySelector: ArrayCallback<T, TKey, TThis>, reduce: (accumulator: TValue, currentValue: T, currentIndex: number, array: readonly T[]) => TValue, initialValue?: TValue, thisArg?: TThis): Map<TKey, TValue> {
    let map = new Map<TKey, TValue>();
    this.forEach((item, index, array) => {
        let key = keySelector.call(thisArg!, item, index, array);
        if (map.has(key))
            map.set(key, reduce(map.get(key)!, item, index, array));
        else if (typeof initialValue != 'undefined')
            map.set(key, reduce(initialValue, item, index, array));
        else
            map.set(key, item as any);
    });
    return map;
}

export function remove<T>(this: T[], item: T): boolean {
    let index = this.indexOf(item);
    if (index >= 0)
        this.splice(index, 1);
    return index >= 0;
}

export function removeAll<T, TThis = void>(this: T[], predicate: ArrayPredicate<T, TThis>, thisArg?: TThis): T[] {
    let removed = [] as any[];
    for (let i = this.length; i >= 0; i--) {
        if (i in this && predicate.call(thisArg as TThis, this[i], i, this)) {
            removed = removed.concat(this.splice(i, 1));
        }
    }
    return removed;
}

export function singleOrNull<T>(this: readonly T[]): T | null {
    return this.length == 1
        ? this[0]
        : null;
}

export function skipAfter<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis>, thisArg?: TThis): T[] {
    let firstMatching = this.findIndex(ignoreUndefined(predicate), thisArg);
    return firstMatching == -1 ? this as T[] : this.slice(0, firstMatching + 1);
}

export function skipWhile<T, TThis = void>(this: readonly T[], predicate: ArrayPredicate<T, TThis>, thisArg?: TThis): T[] {
    let firstNonMatching = this.findIndex(ignoreUndefined(predicate.negate()), thisArg);
    return firstNonMatching == -1 ? [] : this.slice(firstNonMatching);
}

export function sortBy<T, U, TThis = void>(this: readonly T[], selector: (this: TThis, item: T) => U, thisArg?: TThis): T[];
export function sortBy<T, U, TThis = void>(this: readonly T[], selector: (this: TThis, item: T) => U, compare?: (a: U, b: U) => number, thisArg?: TThis): T[];
export function sortBy<T, U, TThis = void>(this: readonly T[], selector: (this: TThis, item: T) => U, compare?: (a: U, b: U) => number, thisArg?: TThis): T[] {
    if (typeof compare != 'function') {
        thisArg = compare;
        compare = ascending();
    }

    return this.clone().sort((a, b) => compare!(selector.call(thisArg as TThis, a), selector.call(thisArg as TThis, b)));
}

export function sortStable<T>(this: readonly T[], compare: Comparator<T> = ascending()): T[] {
    return this.map((item, index) => ({ item, index }))
        .sort((a, b) => compare(a.item, b.item) || ascending()(a.index, b.index))
        .map(i => i.item);
}

export function sum(this: number[]): number {
    return this.reduce((sum, current) => sum + current, 0);
}

export function symmetricDifference<T>(this: readonly T[], other: T[]): T[] {
    return this.except(other).concat(other.except(this));
}

export function toLookup<T, TKey, TThis = void>(this: readonly T[], keySelector: ArrayCallback<T, TKey, TThis>, thisArg?: TThis): Map<TKey, T[]> {
    return this.reduceMap(keySelector, (a, b) => a.concat([b]), [] as any[], thisArg);
}

export function toMap<T, TKey, TThis = void>(this: readonly T[], keySelector: ArrayCallback<T, TKey, TThis>, thisArg?: TThis): Map<TKey, T>;
export function toMap<T, TKey, TValue = T, TThis = void>(this: readonly T[], keySelector: ArrayCallback<T, TKey, TThis>, valueSelector: ArrayCallback<T, TValue, TThis>, thisArg?: TThis): Map<TKey, TValue>;
export function toMap<T, TKey, TValue = T, TThis = void>(this: readonly T[], keySelector: ArrayCallback<T, TKey, TThis>, valueSelector?: ArrayCallback<T, TValue, TThis>, thisArg?: TThis): Map<TKey, TValue> {
    if (!(typeof valueSelector == 'function')) {
        thisArg = valueSelector;
        valueSelector = (i: any) => i;
    }

    let entries = this.map((item, i, array) => [
        keySelector.call(thisArg as TThis, item, i, array),
        valueSelector!.call(thisArg as TThis, item, i, array)
    ]).notFalsy() as [any, any][];
    return new Map(entries);
}

export function toObject<T, TThis = void>(this: readonly T[], keySelector: ArrayCallback<T, string, TThis>, thisArg?: TThis): { [key: string]: T };
export function toObject<T, TValue, TThis = void>(this: readonly T[], keySelector: ArrayCallback<T, string, TThis>, valueSelector: ArrayCallback<T, TValue, TThis>, thisArg?: TThis): { [key: string]: TValue };
export function toObject<T, TValue, TThis = void>(this: readonly T[], keySelector: ArrayCallback<T, string, TThis>, valueSelector?: ArrayCallback<T, TValue, TThis>, thisArg?: TThis): { [key: string]: TValue } {
    return this.toMap(keySelector, valueSelector!, thisArg as TThis).toObject();
}

export function toSet<T>(this: readonly T[]): Set<T> {
    return new Set(this);
}

export function window<T, TKey, TThis = void>(this: readonly T[], getWindowKey: ArrayCallback<T, TKey, TThis>, thisArg?: TThis) {
    let windows = [] as IGrouping<TKey, T>[];
    let currentWindow!: IGrouping<TKey, T>;

    this.forEach((item, index) => {
        let key = getWindowKey.call(thisArg!, item, index, this);

        if (!currentWindow || key != currentWindow.key) {
            currentWindow = Object.assign([item], { key });
            windows.push(currentWindow);
        } else {
            currentWindow.push(item);
        }
    });

    return windows;
}

export function zip<T, U>(this: readonly T[], otherArray: U[]): [T | undefined, U | undefined][];
export function zip<T, U, TResult, TThis = void>(this: readonly T[], otherArray: U[], selector: (a: T, b: U) => TResult, thisArg?: TThis): TResult[];
export function zip<T, U, TResult, TThis = void>(this: readonly T[], otherArray: U[], selector?: (a: T, b: U) => TResult, thisArg?: TThis): TResult[] {
    return zipStatic([this, otherArray], selector!, thisArg);
}

function ignoreUndefined(fn: Function) {
    return function (this: any) {
        return typeof arguments[0] == 'undefined' ? undefined : fn.apply(this, arguments);
    };
}