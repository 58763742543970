export function scrollIntoView(element: Element, yOffset = 0, xOffset = 0) {
    let scrollParent = findScrollParent(element);
    let parentRect = scrollParent.getBoundingClientRect();
    let elementRect = element.getBoundingClientRect();

    if (elementRect.top < parentRect.top + yOffset)
        scrollParent.scrollTop -= (parentRect.top + yOffset - elementRect.top);
    else if (elementRect.bottom > parentRect.bottom - yOffset)
        scrollParent.scrollTop += (elementRect.bottom - (parentRect.bottom - yOffset));

    if (elementRect.left < parentRect.left + xOffset)
        scrollParent.scrollLeft -= (parentRect.left + xOffset - elementRect.left);
    else if (elementRect.right > parentRect.right - xOffset)
        scrollParent.scrollLeft += (elementRect.right - (parentRect.right - xOffset));
}

export function findScrollParent(target: Element) {
    return findParent(target, isScrollElement);
}

export function findOverflowContainer(target: Element) {
    return findParent(target, isOverflowContainer);
}

function findParent(target: Element, predicate: (elementStyle: CSSStyleDeclaration) => boolean) {
    let current = target.parentElement;
    while (current) {
        if (predicate(getComputedStyle(current!)))
            return current!;
        current = current!.parentElement;
    }

    return document.documentElement;
}

function isOverflowContainer(style: CSSStyleDeclaration) {
    return [style.overflow, style.overflowX, style.overflowY].some(o => o != 'visible');
}

function isScrollElement(style: CSSStyleDeclaration) {
    return ['auto', 'scroll'].includes(style.overflowX!)
        || ['auto', 'scroll'].includes(style.overflowY!)
        || ['auto', 'scroll'].includes(style.overflow!);
}

export function isPositioned(style: CSSStyleDeclaration) {
    return style.position != 'static';
}

export function relativeDocumentPosition(a: Node, b: Node) {
    let position = a.compareDocumentPosition(b);
    return position & (Node.DOCUMENT_POSITION_FOLLOWING | Node.DOCUMENT_POSITION_CONTAINED_BY) ? -1
        : position & (Node.DOCUMENT_POSITION_PRECEDING | Node.DOCUMENT_POSITION_CONTAINS) ? 1
            : 0;
}