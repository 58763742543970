import { mapValues } from "@zap/utils/lib/Object";
import { forwardRef } from "@zap/utils/lib/ReactHelpers";
import * as React from 'react';
import { Column } from "../Box";
import { disabledForeground, interactiveForegroundColor, interactiveForegroundHoverColor, successColor, warningColor } from '../CommonStyles';
import { classes, defaultPx, style, StyleCollection, Styled, variable } from '../styling';
import { Icons } from './Icons';

export type IconName = keyof typeof Icons;

export type IconSize = keyof typeof IconSizes;
export const IconSizes = {
    tiny: 18,
    small: 24,
    large: 48
};

export type DefaultColor = keyof typeof DefaultColors;
export const DefaultColors = {
    check: successColor,
    warning: warningColor
}

export type IIconProps = {
    name?: IconName;
    url?: string;
    useCurrentColor?: boolean;
    light?: boolean;
    dark?: boolean;
    disabled?: boolean;
    customColor?: string;
    size?: IconSize;
    nonStandardSize?: number;
    styles?: StyleCollection;
};

export function Icon({ name, url, useCurrentColor, light, dark, disabled, customColor, size = 'small', nonStandardSize, styles }: IIconProps) {
    let iconSize = nonStandardSize ?? IconSizes[size];

    if (!name && !url)
        name = 'blank';

    return name
        ? React.createElement(Icons[name], {
            'data-testid': `icon-${name}`,
            className: classes([
                icon,
                useCurrentColor ? currentColor
                    : light ? lightColors
                        : darkColors,
                (!!customColor || useCurrentColor || light || dark) && overrideColor,
                disabled && iconDisabled,
                styles
            ]),
            height: defaultPx(iconSize),
            width: defaultPx(iconSize),
            style: customColor ? iconColor.set(customColor) : undefined
        } as React.SVGProps<SVGSVGElement>)
        : <Styled.img src={url} width={iconSize} height={iconSize} styles={[icon, styles, ImageIconPadding[size]]} data-testid={`icon-${name}`} />;
}

export let iconColor = variable('color', 'icon-color');

/** Use this to select the icon element. */
export const icon = style('icon', {
    pointerEvents: 'none',
    fill: iconColor.or(interactiveForegroundColor) // Just a default - will be overridden in many icons
});

/** Apply this to any interactive components that contain an icon. */
export const iconHoverContainer = style('icon-hoverContainer', {});

let smallImageIconPadding = style('icon-imageIcon-small', {
    padding: 2
});

let largeImageIconPadding = style('icon-imageIcon-large', {
    padding: 4
});


const ImageIconPadding = {
    tiny: undefined,
    small: smallImageIconPadding,
    large: largeImageIconPadding
};

let overrideColor = style('icon-overrideColor', {
    $: { '*': { fill: `${iconColor} !important` } }
});

let iconDisabled = style('icon-disabled', {});

let lightColors = iconColors('icon-light', '#e2e2e2', '#ffffff', '#808080');
let darkColors = iconColors('icon-dark', interactiveForegroundColor, interactiveForegroundHoverColor, disabledForeground);
let currentColor = iconColors('icon-currentColor', 'currentColor', 'currentColor', 'currentColor');

function iconColors(name: string, enabled: string, hover: string, disabled: string) {
    return style(name, {
        $: {
            [`&:not(.${iconDisabled})`]: iconColor.set(enabled),
            [`&.${iconDisabled}`]: iconColor.set(disabled),
            [`.${iconHoverContainer}:hover &:not(.${iconDisabled})`]: iconColor.set(hover),
            [`.${iconHoverContainer}:focus-within &:not(.${iconDisabled})`]: iconColor.set(hover),
            [`.${iconHoverContainer} &`]: overrideColor,
        }
    });
}

export const testIds = mapValues(Icons, (_, name) => `icon-${name}`);

export function isIconName(value?: string): value is IconName {
    return !!value && Object.keys(Icons).includes(value);
}

export function asIconOrDefault(value?: string | null): IconName | undefined {
    return value
        ? isIconName(value)
            ? value
            : 'warning'
        : undefined;
}

interface IIconWithOverlayProps {
    primary: Exclude<IIconProps, 'disabled' | 'size'>;
    overlay: Exclude<IIconProps, 'disabled' | 'size'>;
    size?: IconSize;
    disabled?: boolean;
    styles?: StyleCollection;
}

export const IconWithOverlay = forwardRef(function IconWithOverlay({ primary, overlay, size = 'small', disabled, styles }: IIconWithOverlayProps, ref: React.Ref<HTMLDivElement>) {
    return <Column ref={ref} spacing="none" height={IconSizes[size]} styles={styles}>
        <Icon {...primary} size={size} disabled={disabled} />
        <Icon {...overlay} size={size} disabled={disabled} styles={[overlay.styles, overlayStyles]} />
    </Column>
})

let overlayStyles = style('iconWithOverlay-overlay', {
    position: 'relative',
    transform: { translateY: '-100%' }
});
