import { cloneAndGetRef, useEventListener } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { scrollIntoView } from "./DomHelpers";

export interface IAutoScrollProps {
    enabled?: boolean;
    children: React.ReactElement;
}

export class AutoScroll extends React.Component<IAutoScrollProps> {
    private _hasScrolled = false;

    render(): React.ReactElement<any> {
        return cloneAndGetRef(this.props.children, (element) => this.scrollTo(element));
    }

    scrollTo(e: Element | null) {
        if (!this._hasScrolled && e) {
            if (this.props.enabled)
                e.scrollIntoView();
            this._hasScrolled = true;
        }
    }
}

export function useScrollTo<TElement extends Element>(scroll: boolean, yOffset?: number, xOffset?: number) {
    let ref = useRef<TElement>(null);

    useEffect(() => {
        if (scroll && ref.current)
            scrollIntoView(ref.current, yOffset, xOffset);
    }, [scroll]);

    return ref;
}

export function useIsScrolled<TElement extends HTMLElement>() {
    let [isScrolled, setIsScrolled] = useState({ x: false, y: false });

    let ref = useEventListener<'scroll', TElement>('scroll', (e, element) => {
        let x = !!element.scrollLeft;
        let y = !!element.scrollTop;
        if (isScrolled.x != x || isScrolled.y != y)
            setIsScrolled({ x, y });
    });

    return [isScrolled, ref] as const;
}